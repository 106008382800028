(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name persons.person
   * @description
   *
   */
  angular
  .module('neo.home.persons.person', [
    'ui.router',
    'neo.home.persons.person.information'
  ]);
}());
